import React, { useContext, useState, useEffect } from "react";
import styles from "./Header.module.scss";
import { useNavigate, Link } from "react-router-dom";
import classnames from "classnames";

const Header = ({ no_animation, no_background }) => {
    let navigate = useNavigate(); 
    const [scale, setScale] = useState(1); 

    useEffect(() => {
    }, []);

    window.onscroll = function() {
        const scrollY = window.pageYOffset;
        const newScale = Math.max(0.8, 1 - (scrollY / 100) * 0.3);
        setScale(newScale);
    };

    const routeChange = (path) =>{ 
        navigate('/' + path);
    }

    const translateY = 1 - scale;

    return (
        <header>
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png"></link>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
            <link rel="manifest" href="/site.webmanifest"></link>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"></link>
            <title>cuter.co</title>

            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <meta name="msapplication-TileColor" content="#da532c"></meta>
            <meta name="theme-color" content="#1F1F1F"></meta>
            <div className={classnames(styles.outer_container)} onClick={() => this.forceUpdate()}>
                <div className={classnames(styles.inner_container)}> 
                    <Link 
                        to={"/"} 
                        href={"./"} 
                        style={{
                            transformOrigin: 'top',
                            transform: `translateY(${translateY}px) scale(${scale})`, 
                        }} 
                        className={classnames(styles.img_container)}
                    >
                        <img src="https://i.imgur.com/PZGU75c.png"/>
                    </Link>
                   {/* <div className={classnames(styles.right_icons)}>
                        <img src="https://i.imgur.com/NmweZjN.png"/>
                        <img src="https://i.imgur.com/TpW7OPg.png"/>
                        <img src="https://i.imgur.com/C76nrBB.png"/>
                    </div>*/}
                </div>
            </div>
        </header>
    );
};

export default Header;