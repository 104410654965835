import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';

import AppContext from './contexts/AppContext';

import "./styles/app.scss";

/*
sudo iptables -t nat -A PREROUTING -p tcp --dport 80 -j REDIRECT --to-ports 8000
*/
ReactDOM.render(
    <GoogleOAuthProvider clientId="214440267662-pmg5je04ga5rbas4licodr5fj31jqkjv.apps.googleusercontent.com">
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </GoogleOAuthProvider>,
    document.getElementById('root')
);