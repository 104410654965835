import React from "react";

import styles from "./Footer.module.scss";

const Footer = ({ credits }) => {
    return (
    <footer>
      <img/> 
      <div className={styles.middle_content}>
        <p>Powered by <a href="https://artfol.co" target="_blank">Artfol</a> </p>
        <p>cuter.co © 2024 </p>
      </div>
    </footer>
    );
};

export default Footer;
