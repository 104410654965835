import { validateRefreshToken } from "./api";

function hexToRgba(hex, opacity) {
    console.log("hexToRgba: " + hex)
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function startSession(response) {
    const userInfo = response;
    console.log(userInfo)
    localStorage.setItem('user', JSON.stringify(userInfo));
    return userInfo;
}

const validateSession = async () => {
    console.log("validateSession")
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        const currentTime = new Date().getTime();
        if (currentTime < parsedUser.expiry_date) {
            return { refresh_is_valid: true, ...parsedUser};
        } else {
            // Token expired, attempt to refresh
            try {
                const response = await validateRefreshToken(parsedUser.refresh_token);
                if (response.refresh_is_valid) {
                    const updatedUser = {
                        ...parsedUser,
                        access_token: response.access_token,
                        expiry_date: response.expiry_date,
                        refresh_token: response.refresh_token
                    };
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                    return updatedUser;
                } else {
                    localStorage.removeItem('user');
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
                localStorage.removeItem('user');
                return { error: true };
            }
        }
    }
};

const logout = () => {
    localStorage.removeItem('user');
};

export {
    hexToRgba,
    logout,
    startSession,
    validateSession,
};
