import React, { useContext, useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useNavigate, useParams, Link } from "react-router-dom";
import { useStickyBox } from "react-sticky-box";
import { useLocation  } from "react-router";
import { axios  } from "axios";
import { AspectRatio } from 'react-aspect-ratio';
import { Helmet, HelmetProvider } from "react-helmet-async";
import FlatList from 'flatlist-react';
import FadeIn from 'react-fade-in';
import moment from 'moment';
import Ratio from 'react-ratio';
import AppContext from '../../contexts/AppContext';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "../../components/Image";
import styles from "./Profile.module.scss";
import glbl from "../../Global.module.scss";
import { hexToRgba } from "../../services/utils";
import { 
    addCard,
    getCustomerDashboard,
    getExpressDashboard,
    getUser, 
    getStripeAdmin,
    createCheckoutSession 
} from "../../services/api";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51N2lCNBd6wB4GZO9tj56rJuiKTOIZ0hk7Xd9iB1jkQojifzbUZ7st5k3e1tkfjIWOLmVY1UPBofBzeIMnDDYKlG900aECh17Og');

const style = {
    fontFamily: 'Monospace, serif'
}


const PaymentForm = ({ user, hasCard, setHasCard }) => {
  const [isAddingCard, setIsAddingCard] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
    base: {
      fontSize: '14px',
      color: '#000000', // Adjust text color as needed
      backgroundColor: '#F1F1F1',
      border: 'none',
      borderRadius: '22px',
      padding: '20px',
      boxSizing: 'border-box',
      '::placeholder': {
        color: '#aab7c4', // Placeholder text color
      },
    },
    complete: {
        height: '100px',
      color: '#4CAF50', // Change this to any color for a completed input
    },
    invalid: {
      color: '#FF5252', // Error color
    },
    },
  };

  const handleAddCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    if (error) {
      console.error(error);
      alert('Error creating card token. Please try again.');
    } else {
      try {
        const response = await addCard(user.username, parsedUser.refresh_token, token.id);
        console.log(response)
        // if (response.data.success) {
        //   setHasCard(true);
        //   setIsAddingCard(false);
        //   alert('Card added successfully!');
        // }
      } catch (error) {
        console.error('Error adding card:', error);
        alert('Failed to add card. Please try again.');
      }
    }
  };

  return (
    <div>
      <h2>Payment Information</h2>
      {hasCard ? (
        <button onClick={() => setIsAddingCard(true)}>Edit Card</button>
      ) : (
        <button onClick={() => setIsAddingCard(true)}>Add a Card</button>
      )}
      
      {isAddingCard && (
        <form onSubmit={handleAddCard}>
            <div className={classnames(glbl.card_element)}>
                <CardElement options={cardStyle}/>
            </div>
            <button type="submit" onClick={handleAddCard}>Save Card</button>
        </form>
      )}
    </div>
  );
};

const SettingsModule = () => {
    return(
        <div>
            <p>(Display) Name:*</p>
            <input type="text" placeholder="Jazzy Pineapple"></input>
            <p>Username:*</p>
            <input type="text" placeholder="@jazzypineapple"></input>
            <p>About:</p>
            <textarea placeholder="Hello world!" rows="3"></textarea>
            <p>Links:</p>
            <input type="text" placeholder="https://"></input>
            <input type="text" placeholder="https://"></input>
            <input type="text" placeholder="https://"></input>
        </div>
    )
}

const SetupModule = () => {
    return(
        <div className={classnames(styles.goal_module, styles.floating_module)}>
            <p style={style}>Finish setting up your profile!</p>
            <p style={style}>You’re nearly there! Finish your profile by setting up a Stripe account to start receiving payments!</p>
        </div>
    )
}

const GoalModule = () => {
    return(
        <div className={classnames(styles.goal_module, styles.floating_module)}>
            <p style={style}>🚀 Money towards buying a new tablet!</p>
            <div className={classnames(styles.progress_bar_container)}>
              <div className={classnames(styles.progress_bar)} style={{ width: `42%` }}>
                <span style={style}>4%</span>
              </div>
            </div>
        </div>
    )
}

const StripeSettingsModule = ({ user, expressAccount, customerAccount }) => {
  const [accountData, setAccountData] = useState({
    displayName: '',
    username: '',
    about: '',
    links: ['', '', ''],
  });
  const [hasCard, setHasCard] = useState(false);

  useEffect(() => {
    console.log( expressAccount  )
    console.log( customerAccount  )
    getData();
  }, []);

  const getData = async() => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);
    const s = await getStripeAdmin(user.username, parsedUser.refresh_token);

    console.log(s)
    setHasCard(s.customerAccount.default_source !== null);
  }

    return(
        <div className={classnames(styles.module)}>
            <div className={classnames(styles.module_container)}>
                <h1>Stripe Dashboard</h1>
                <Elements stripe={stripePromise}>
                    <PaymentForm user={user} hasCard={hasCard} setHasCard={setHasCard} />
                </Elements>
            </div>
        </div>
    )
}

const TransactionModule = ({ user, expressAccount, customerAccount}) => {
    const [tab, setTab] = useState('Received');
    return(
        <div className={classnames(styles.module)}>
            <div className={classnames(styles.module_container)}>
                <p onClick={() => setTab('Received')}>Received</p>
                <p onClick={() => setTab('Sent')}>Sent</p>
                {customerAccount && tab === 'Sent' ?
                    customerAccount.recentTransactions.map(function(e, index) {
                        return (
                            <div key={index} className={classnames(styles.transaction_module, styles.floating_module)}>
                                <div className={classnames(styles.profile_picture)}>

                                </div>
                                <div className={classnames(styles.vertical_grid)}>
                                    <div className={classnames(styles.grid)}>
                                        <p>
                                            <b>
                                                {e.sender}
                                            </b>
                                        </p>
                                        <p style={{textAlign: 'right'}}>
                                            <b>
                                                {e.amount}
                                            </b>
                                        </p>
                                    </div>
                                    <div className={classnames(styles.grid)}>
                                        <p>Description</p>
                                        <p style={{textAlign: 'right'}}>10/10/36</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) 
                    : expressAccount && tab === 'Received' ?
                    expressAccount.recentTransactions.map(function(e, index) {
                        return (
                            <div key={index} className={classnames(styles.transaction_module, styles.floating_module)}>
                                <div className={classnames(styles.profile_picture)}>

                                </div>
                                <div className={classnames(styles.vertical_grid)}>
                                    <div className={classnames(styles.grid)}>
                                        <p>
                                            <b>
                                                {e.sender}
                                            </b>
                                        </p>
                                        <p style={{textAlign: 'right'}}>
                                            <b>
                                                {e.amount}
                                            </b>
                                        </p>
                                    </div>
                                    <div className={classnames(styles.grid)}>
                                        <p>Description</p>
                                        <p style={{textAlign: 'right'}}>10/10/36</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) 
                    : 
                    null
                }
            </div>
        </div>
    )
}

const Profile = () => {
    const { username } = useParams();
    const navigate = useNavigate(); 
    const location = useLocation();
    const [tab, setTab] = useState("profile");
    const [visibleTabs, setVisibleTabs] = useState("profile");
    const [user, setUser] = useState({
        username: "",
        theme: '#D1D1D1'
    });
    const [stripeSettings, setStripeSettings] = useState({
    })
    const [transactionData, setTransactionData] = useState({
        expressAccount: null,
        customerAccount: null,
    })

    useEffect(() => {
      window.scrollTo(0, 0);
      const pathname = location.pathname;
      getProfile(username);
    }, [location])

    const getProfile = async(username) => {
        var response = await getUser(username, 'username');
        setUser(response);
        // when response.isPayoutEnabled, user can receive payouts
        // when response.isChargeEnabled, user can be paid
        // when response.isRestricted, user has limitations on account

        // if has access to admin
        if(true) {
            getTransactionData(username);
        }


    }

    const getTransactionData = async(username) => {
        const storedUser = localStorage.getItem('user');
        const parsedUser = JSON.parse(storedUser);

        const customerData = await getCustomerDashboard(username, parsedUser.refresh_token);
        const expressData = await getExpressDashboard(username, parsedUser.refresh_token);

        setTransactionData({
            customerAccount: customerData,
            expressAccount: expressData
        });
    }

    const handleCheckout = async () => {
        const stripe = await stripePromise;

        const storedUser = localStorage.getItem('user');
        const parsedUser = JSON.parse(storedUser);

        // placeholder customerId
        const response = await createCheckoutSession(user.username, 100, parsedUser.username);
        console.log(response);
        if(response.error) {
            // Handle error
            return;
        }

        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: response.id,
        });

        if (result.error) {
          // Handle any errors that occur during the redirect
          console.error(result.error);
        }
    };

    return (
        <>
            <div>
                <Header/>
            </div>
            <div style={{position: 'fixed', zIndex: -1, height: '100vh', width: '100vw', backgroundColor: 'white'}}/>
            <div style={{backgroundColor: hexToRgba(user.theme, 0.8), minHeight: '100vh'}}>           
                <HelmetProvider>
                    <Helmet>
                        <title>{"cuter.co"}</title>
                    </Helmet>
                </HelmetProvider>
                <div style={{position: 'fixed', zIndex: 999, top: 200, left: 0}}>
                    <div onClick={() => setTab('profile')} style={{height: 20, width: 20, backgroundColor: 'tan', margin: 10}}/>          
                    <div onClick={() => setTab('transactions')} style={{height: 20, width: 20, backgroundColor: 'tan', margin: 10}}/>          
                    <div onClick={() => setTab('settings')} style={{height: 20, width: 20, backgroundColor: 'tan', margin: 10}}/>      
                    <div onClick={() => setTab('stripe_settings')} style={{height: 20, width: 20, backgroundColor: 'tan', margin: 10}}/> 
                </div>
                <div className={classnames(styles.outer_container)}>     
                    <div className={classnames(styles.profile_container)}>
                        <div className={classnames(styles.banner)} style={{backgroundColor: user.theme}}>
                        </div>
                        <div className={classnames(styles.inner_container)}>

                            <div className={classnames(styles.profile)}>
                                <img src="https://i.imgur.com/Lj3E6sv.png"/>
                            </div>
                            <h1 style={style}>{user.username}</h1>
                            <p style={style}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                    </div>
                    {tab === "profile" ?
                    <div className={classnames(styles.module)}>
                        <SetupModule>

                        </SetupModule>

                        <h2 style={style}>Donate to User so they can buy art supplies!</h2>

                        <button style={{display: 'block', margin: 'auto'}} onClick={handleCheckout}>
                          Donate
                        </button>

                        <h2>Goals 🤩</h2>

                        <div className={classnames(styles.module_container)}>
                            <GoalModule>

                            </GoalModule>
                            
                            <GoalModule>

                            </GoalModule>
                            
                            <GoalModule>

                            </GoalModule>
                            
                            <GoalModule>

                            </GoalModule>
                        </div>
                    </div>
                    : tab === "transactions" ?
                    <TransactionModule user={user} expressAccount={transactionData.expressAccount} customerAccount={transactionData.customerAccount}/>
                    : tab === "stripe_settings" ?
                    <StripeSettingsModule user={user} expressAccount={transactionData.expressAccount} customerAccount={transactionData.customerAccount}/>
                    :
                    <div className={classnames(styles.module, styles.settings_module)}>
                        <SettingsModule>

                        </SettingsModule>
                    </div>
                    }
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </>
    )
};

export default Profile;
