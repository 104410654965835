const BASE = "http://localhost:5000/api/v1/";

var HEADER = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
});

function getPaymentLink(userId, amount) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            userId: userId,
            amount: amount
        })
    };
    return fetch(BASE + "create-payment-link", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function getStripeAdmin(user, refreshToken) {
    const requestOptions = {
        method: 'GET',
        headers: HEADER,
        redirect: 'follow'
    };
    const url = `${BASE}stripe-accounts/${user}?refresh=${encodeURIComponent(refreshToken)}`;
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function getCustomerDashboard(user, refreshToken) {
    const requestOptions = {
        method: 'GET',
        headers: HEADER,
        redirect: 'follow'
    };
    const url = `${BASE}customer-dashboard/${user}?refresh=${encodeURIComponent(refreshToken)}`;
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function getExpressDashboard(user, refreshToken) {
    const requestOptions = {
        method: 'GET',
        headers: HEADER,
        redirect: 'follow'
    };
    const url = `${BASE}express-dashboard/${user}?refresh=${encodeURIComponent(refreshToken)}`;
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function addCard(user, refreshToken, stripeToken) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            token: stripeToken,
        })
    };
    const url = `${BASE}add-card/${user}?refresh=${encodeURIComponent(refreshToken)}`;
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function createCheckoutSession(username, amount, selfUsername) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            selfUsername: selfUsername,
            username: username,
            amount: amount
        })
    };
    return fetch(BASE + "create-checkout-session", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function onboardConnectAccount(accountId) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            accountId: accountId
        })
    };
    return fetch(BASE + "onboard-user", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function createUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            email: data.email,
            name: data.name,
            username: data.username,
            links: data.links,
            theme: data.theme,
            secondaryColor: data.secondaryColor,
        })
    };
    return fetch(BASE + "create/user", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function getUser(id, query) {
    const requestOptions = {
        method: 'GET',
        headers: HEADER,
        redirect: 'follow'
    };
    return fetch(BASE + "get/user/" + id + '?query=' + query, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function login(email) {
    const requestOptions = {
        method: 'GET',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            email: email
        })
    };
    return fetch(BASE + "login", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}


function validateRefreshToken(refreshToken) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            refreshToken: refreshToken
        })
    };
    return fetch(BASE + "auth/validate-refresh", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

export {
    addCard,
    createCheckoutSession,
    createUser,
    getCustomerDashboard,
    getExpressDashboard,
    getStripeAdmin,
    getPaymentLink,
    getUser,
    login,
    onboardConnectAccount,
    validateRefreshToken
};
