import React, { useContext, useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useNavigate, Link } from "react-router-dom";
import { useStickyBox } from "react-sticky-box";
import { useLocation  } from "react-router";
import { AspectRatio } from 'react-aspect-ratio';
import { Helmet, HelmetProvider } from "react-helmet-async";
import FlatList from 'flatlist-react';
import FadeIn from 'react-fade-in';
import moment from 'moment';
import Ratio from 'react-ratio';
import AppContext from '../../contexts/AppContext';
import SweetAlert from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "../../components/Image";
import styles from "./Privacy.module.scss";

const ReactSweetAlert = withReactContent(SweetAlert)

const Profile = () => {
  const navigate = useNavigate(); 
  const location = useLocation();

    return (
        <>
            <div style={{backgroundColor: '#131313'}} className="container">           
                <p>
                    Privacy Policy<br/><br/>
Scope of Policy<br/><br/>
This policy describes how we use the personal information that we collect from you in connection with your interactions with Cuter. You must comply with all applicable privacy laws and must not collect, use, retain or disclose any third party's personal information without their consent unless otherwise permitted by law to do so. This privacy notice is compliant with the General Data Protection Regulation (GDPR) and the UK's Data Protection Act 2018.<br/><br/>

Cuter will collect personal information about you including your contact information and date of birth. You acknowledge that we may use your personal information to allow us to communicate with you, manage your account with us, monitor your compliance with this Agreement, and manage any services we provide to you or other users, and to verify your personal information.<br/><br/>

Upon completion of sign up, a profile page is automatically created for you, and some of your personal information may be made available to the general public. For your protection, please do not share sensitive information on Cuter.<br/><br/>

We will not sell or rent your personal information to third parties, and we will obtain your consent to collect, use or disclose personal information except where we are authorized or required by law to do so without consent.<br/><br/>

Liabilities<br/><br/>
We will protect the personal information in our custody or control by making reasonable security arrangements to prevent unauthorized access, collection, use, disclosure, copying, modification, disposal or similar risks. We will not otherwise disclose your personal information or sell your personal information to third parties. If we suspect you of fraudulent or other prohibited activities, we may disclose your personal information to the police or other authorities.<br/><br/>

Our website, social media sites and mobile applications may contain links to websites of third parties. We are not responsible for the privacy practices or the content of those other websites.<br/><br/>

Changes to the Platform<br/><br/>
From time to time, Cuter may add new features to the platform, remove existing features from the platform, or otherwise modify the platform (including their functionality, look-and-feel, universal resource locators and software components).


                </p>
            </div>
            <div>
                <Footer/>
            </div>
        </>
    )
};

export default Profile;
