import React, { useContext, useCallback, useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { useNavigate, Link } from "react-router-dom";
import { useLocation  } from "react-router";
import { AspectRatio } from 'react-aspect-ratio';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import FlatList from 'flatlist-react';
import axios from 'axios';
import AppContext from '../../contexts/AppContext';

import { startSession } from "../../services/utils";
import { onboardConnectAccount } from '../../services/api';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./Dashboard.module.scss";

const StripeExpressDashboard = ({ userId }) => {
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDashboardUrl = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/v1/express-dashboard/${userId}`);
        console.log(response)
        setDashboardUrl(response.data.url);
      } catch (err) {
        setError('Failed to fetch dashboard URL');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardUrl();
  }, [userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="w-full h-screen">
      <iframe
        src={dashboardUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="payment"
      ></iframe>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div style={{height: '100vh', width: '100vw'}}>
      <StripeExpressDashboard userId="user_123" />
    </div>
  );
};

export default Dashboard;